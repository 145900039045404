function MainButton(props) {
    return (
        <a className="mainButton" style={{
            backgroundColor: props.backgroundColor, 
            color: props.textColor}}
            href={props.href}>
            <strong>{props.children}</strong>
        </a>
    )
};

export default MainButton;