import logo from './logo.png';
import './App.css';
import { useSpring, animated } from 'react-spring'
import MainButton from './MainButton'
import { Accordion, Card} from 'react-bootstrap';
import ReactPlayer from 'react-player'

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const transBackground = (x, y) => `translate3d(${x / 10}px,${y / 10 - 1200}px,0)`
const transRedPaddle = (x, y) => `translate3d(${x / 8 + window.innerWidth * 0.3}px,${y / 8 - window.innerHeight}px,0)`
const transBluePaddle = (x, y) => `translate3d(${x / 6 - (window.innerWidth * 0.375)}px,${y / 6 - window.innerHeight}px,0)`
const transBall = (x, y) => `translate3d(${x / 3.5 }px,${y / 3.5 - window.innerHeight * 1.2}px,0)`

function App() {
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <strong style={{zIndex: 1}}>
          ACTION-ARCADE TABLE TENNIS
        </strong> 
        <br></br>
        <div className="Main-buttons-container">
          <MainButton backgroundColor="#8BAA38" textColor="White">Steam (Coming Soon)</MainButton>
          <MainButton backgroundColor="White" textColor="Black" href="https://discord.gg/TtPrRacjSj">Join the Alpha</MainButton>
        </div>
        <h4 style={{zIndex: 1, position: 'absolute', bottom:60}}>MORE</h4>
        <img alt="Down arrow" style={{zIndex: 1, position: 'absolute', bottom:20}} src="/down.png"></img>
      </header>
      <div className="App-body">
        <div className="Information-section" >
          {/*<video autoPlay loop muted width="720" style={{float:"left"}}>
            <source src='/playairpong/multiplayer.mp4' type="video/mp4"></source>
            Your browser does not support the video tag.
  </video>
  <ReactPlayer style={{position: "relative", float: "left"}} playing={true} url='/playairpong/multiplayer.mp4'
                width="40vw"
                height="100%"
                loop={true}
                muted={true}
                
            />*/}
          <img src='/multiplayer.gif' alt='Multiplayer demo' style={{position: "relative", float: "left", width:"40vw"}}/>
          <div className="Information-section-text" style={{right: 0, paddingLeft:'6.5vw', paddingRight:'7.5vw'}}>
            <h2 style={{paddingLeft:'inherit'}}>YOUR PADDLE IN YOUR HANDS</h2>
            <h3 style={{paddingLeft:'inherit'}}>Rotate your phone, and your paddle rotates. Swing your phone, and your paddle swings. No extra equipment needed, only your PC and your phone.</h3>
          </div>
        </div>
        <div className="Information-section">
          <img src='/paddle-effect.gif' alt='Special effects demo' style={{position: "relative", float: "right", width:"32vw"}}/>
          <div className="Information-section-text" style={{left: 0, paddingLeft:'7.5vw'}}>
            <h2>SPECIAL MOVES AND POWER UPS</h2>
            <h3>Throw off your opponent with power shots, spins, extra balls, and more! (Coming Soon)</h3>
          </div>
        </div>
        <div className="Information-section" >
          <img src='/preview-coming-soon-base.png' alt='Preview coming soon' style={{position: "relative", float: "left", width:"32vw"}}/>
          <div className="Information-section-text" style={{ right: 0, paddingLeft:'6.5vw', paddingRight:'7.5vw'}}>
            <h2 style={{paddingLeft:'inherit'}}>MULTIPLE GAMEMODES</h2>
            <h3 style={{paddingLeft:'inherit'}}>Play with or without special moves. Use one ball or several. Face off against one opponent or seven at the same time. The options are virtually limitless! (Coming Soon)</h3>
          </div>
          
        </div>
        <div className="Information-section" style={{marginTop:50}}>
            <h1>FAQ</h1>
            <Accordion className='FAQ-accordion'>
              <Card className='FAQ-accordion-card'>
                <Accordion.Toggle as={Card.Header} eventKey="0" className='FAQ-accordion-card-header'>
                  How do I play the game?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" >
                  <Card.Body className='FAQ-accordion-card-body'>You can obtain exclusive alpha access by joining the Discord <a href="https://discord.gg/TtPrRacjSj">here</a>. Instructions are in the #welcome channel! The game will eventually be on the Play Store, App Store, and Steam for the beta and future versions.</Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className='FAQ-accordion-card'>
                <Accordion.Toggle as={Card.Header} eventKey="1" className='FAQ-accordion-card-header'>
                  How far is the game in development?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className='FAQ-accordion-card-body'>The game is currently in alpha testing. There are some noticeable bugs and the core control mechanics are still being tweaked. There is a solo practice mode and a multiplayer mode with some bugs that are being worked on. Join <a href="https://discord.gg/TtPrRacjSj">the Discord</a> to help give feedback on the control mechanics and test out multiplayer!</Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className='FAQ-accordion-card'>
                <Accordion.Toggle as={Card.Header} eventKey="2" className='FAQ-accordion-card-header'>
                  What platforms are currently supported?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className='FAQ-accordion-card-body'>Airpong is current available for Android (for the controller) and PC, Mac, and Linux (for the game). iOS will come with the beta!</Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className='FAQ-accordion-card'>
                <Accordion.Toggle as={Card.Header} eventKey="3" className='FAQ-accordion-card-header'>
                  When is the beta coming out?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className='FAQ-accordion-card-body'>The beta version of Airpong will be released when the core mechanics have been more solidified and bugs have been ironed out for a very playable multiplayer experience. The exact date of this is TBD, but projection is some time in <i>Q4 2021</i>.</Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className='FAQ-accordion-card'>
                <Accordion.Toggle as={Card.Header} eventKey="4" className='FAQ-accordion-card-header'>
                  Is/will it be free?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className='FAQ-accordion-card-body'>The alpha test and future versions of the game are and will be completely free to play. Eventually, there will be an in-game shop for cosmetics and other items which can be purchased.</Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className='FAQ-accordion-card'>
                <Accordion.Toggle as={Card.Header} eventKey="5" className='FAQ-accordion-card-header'>
                  Where can I ask other questions?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className='FAQ-accordion-card-body'>Join our Discord community <a href="https://discord.gg/TtPrRacjSj">here</a> to ask questions and discuss the game with the developer and other memberes of the community!</Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
        </div>
        <div className="Information-section" style={{marginTop:50, marginBottom:20, backgroundColor: 'rgba(0, 0, 0, 1) '}}>
            <a href="https://discord.gg/TtPrRacjSj"><img src='/discord.png' alt='Discord'/></a>
            <a href="https://twitter.com/PlayAirpong"><img src='/twitter.png' alt='Twitter' style={{marginLeft: 10, marginRight: 10}}/></a>
            <a href="https://www.instagram.com/playairpong/"><img src='/instagram.png' alt='Instagram'/></a>
        </div>
      </div>
      <div className="Spring-container" onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
          <animated.div className="background" style={{ transform: props.xy.interpolate(transBackground) }} />
          <animated.div className="redPaddle" style={{ transform: props.xy.interpolate(transRedPaddle) }} />
          <animated.div className="bluePaddle" style={{ transform: props.xy.interpolate(transBluePaddle) }} />
          <animated.div className="ball" style={{ transform: props.xy.interpolate(transBall) }} />
        </div>
    </div>
  );
}

export default App;
